<template>
  <div class="planDetailWrap">
    <div class="topWrap">
      <div class="topContenWrap main">
        <p class="breadCrumb mb30"  v-if="$route.query.metaType&&!$route.query.fromType">{{$route.query.metaType==1?'首页':'学习'}} > 课程 > 教学计划</p>
        <p class="breadCrumb mb30" v-if="$route.query.fromType==3">学习 > 教学计划</p>
        <div class="infoWrap">
          <img src="../../assets/images/planImg.png" alt="" />
          <div class="rightInfo">
            <p class="rightInfoTitle">{{ planCourse.title }}</p>
            <p>
              <span>开课学期：{{ planCourse.term_name }}</span>
              <span>授课院系：{{ planCourse.faculty_name }}</span>
<!--              <span>课程：{{ planCourse.course_num }}个</span>-->
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="main mt20">
      <div v-if="detailList.length > 0">
        <div v-for="(item, index) in detailList" :key="index" class="itemWrap">
          <img :src="item.image" alt="" />
          <div class="rightItemWrap">
            <div class="contentWrap">
              <div class="tagWrap">
                <el-tag size="small" class="marks">{{ item.course_type_name }}</el-tag>
                <span class="tagTitle">{{ item.title }}</span>
                <p>{{ item.credit }}学分</p>
              </div>
              <div class="progressWrap">
                <!-- <div class="lineWrap">
                <div
                  class="lineSonWrap"
                  :class="
                    item.status == 2 || item.status == 3
                      ? 'grayLine'
                      : item.finish_rate != 100
                      ? 'blueLine'
                      : item.finish_rate == 100
                      ? 'greenLine'
                      : ''
                  "
                  :style="'width:' + item.finish_rate * 2 + 'px'"
                ></div>
              </div>
              <span class="greenColor" v-if="item.finish_rate == 100">已学完</span>
              <span class="grayColor" v-if="item.finish_rate != 100"
                >{{ item.finish_rate }}%</span
              > -->
                <div class="lineWrap">
                  <div
                    class="lineSonWrap"
                    :class="
                      item.finish_rate != 100
                        ? 'blueLine'
                        : item.finish_rate == 100
                        ? 'greenLine'
                        : ''
                    "
                    :style="'width:' + item.finish_rate * 2 + 'px'"
                  ></div>
                </div>
                <span class="greenColor" v-if="item.finish_rate == 100"
                  >已学完</span
                >
                <span class="grayColor" v-if="item.finish_rate != 100"
                  >{{ item.finish_rate }}%</span
                >
              </div>
            </div>
            <div class="statusWrap">
              <el-button type="primary" round @click="jump(item)"
                >去学习</el-button
              >
            </div>
          </div>
        </div>
        <p class="moreBtn" @click="getMore">
          <i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { planCourseList } from "../../assets/api";
export default {
  data() {
    return {
      detailList: [],
      page: 1,
      limit: 20,
      btnMessage: "加载更多",
      planCourse: {},
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      const params = {
        project_id: this.$route.query.project_id,
        limit: this.limit,
        page: this.page,
      };
      planCourseList(params).then((res) => {
        if (res.code == 0) {
          this.planCourse = res.data.row;
          if (res.data.list.length > 0) {
            this.detailList = this.detailList.concat(res.data.list);
          } else {
            this.btnMessage = "暂无更多";
          }
        } else {
          this.page--;
        }
      });
    },
    getMore() {
      if (this.btnMessage == "加载更多") {
        this.page++;
        this.getList();
      }
    },
    jump(item) {
      this.$router.push({
        path: "/learn",
        query: {
          course_id: item.id,
          metaType:this.$route.query.metaType
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
  .marks{ color: var(--change-color); background:#fff;border:1px solid var(--change-color);}
.planDetailWrap {
  .topWrap {
    width: 100%;
    height: 180px;
    background: url(../../assets/images/topBg.png) top center no-repeat;
    .topContenWrap {
      color: rgba(255, 255, 255, 0.6);
      padding: 20px;
      box-sizing: border-box;
      font-size: 14px;
      .infoWrap {
        display: flex;
        img {
          width: 64px;
          height: 64px;
          margin-right: 20px;
        }
        .rightInfo {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          box-sizing: border-box;
          font-size: 16px;
          p {
            display: flex;
            align-items: center;
            color: #fff;
            span {
              margin-right: 30px;
              color: rgba(255, 255, 255, 0.6);
            }
          }
          .rightInfoTitle {
            font-size: 24px;
            font-weight: bold;
          }
        }
      }
    }
  }
  .itemWrap {
    height: 173px;
    background: #fff;
    margin-bottom: 20px;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    img {
      width: 200px;
      height: 112px;
      border-radius: 6px;
      margin-right: 20px;
    }
    .rightItemWrap {
      width: calc(100% - 220px);
      display: flex;
      justify-content: space-between;
      .contentWrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .tagWrap {
          .tagTitle {
            font-size: 16px;
            margin-left: 10px;
          }
          p {
            margin-top: 10px;
            font-size: 14px;
            color: #888;
          }
        }
        .progressWrap {
          display: flex;
          align-items: center;
          .lineWrap {
            width: 200px;
            height: 6px;
            border-radius: 3px;
            background: #eeeeee;
            position: relative;
            .lineSonWrap {
              position: absolute;
              left: 0;
              top: 0;
              height: 6px;
              border-radius: 3px;
            }
            .greenLine {
              background: #42c9ab;
            }
            .blueLine {
              background: #507fff;
            }
            .grayLine {
              background: #dbdbdb;
            }
          }
          .greenColor {
            margin-left: 10px;
            color: #42c9ab;
            font-size: 14px;
          }
          .grayColor {
            margin-left: 10px;
            color: #888888;
            font-size: 14px;
          }
        }
      }
      .statusWrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .el-button.is-round {
          padding: 10px 20px;
        }
        .el-button--info {
          background: #dbdbdb;
          border: 1px solid #dbdbdb;
        }
        img {
          width: 72px;
          height: 72px;
        }
      }
    }
  }
  .pageWrap {
    margin-top: 10px;
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
.moreBtn {
  margin: 0 auto;
  margin-top: 30px;
  width: 100px;
  height: 30px;
  font-size: 14px;
  border-radius: 18px;
  border: 1px solid #e1e1e1;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  color: #666;
  &:hover{
        background:var(--change-color);
        border:1px solid var(--change-color);
        color:#fff;
      }
  i {
    font-size: 14px;
  }
}
</style>
